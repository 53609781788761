import FavoriteBtn from "../../Assets/FavoriteBtn/FavoriteBtn"
import { useEffect, useState } from "react"
import { getApiUrl } from "../../../utils/getApiUrl"
import Avatar from "../../Assets/Avatar/Avatar"
import moment from "moment"
import Label from "../../Assets/Label/Label"
import { useAppSelector } from "../../../hooks"
import { selectAuth } from "../../../redux/slice/auth"
import { setFavoriteArticles, toggleFavoriteArticles } from "../../../redux/slice/articles"
import {
  useGetArticleQuery,
  useGetFavoriteArticlesQuery,
  useSetFavoriteArticleMutation,
} from "../../../redux/api/articles"
import { useDispatch } from "react-redux"
import clsx from "clsx"
import styles from "./Article.module.scss"

const Article = ({ id }: { id: string }) => {
  const dispatch = useDispatch()
  const { user } = useAppSelector(selectAuth)

  const { data: article, isLoading: isArticleLoading, isFetching } = useGetArticleQuery(id)
  const { data: favoriteArticlesList, isLoading: isFavoriteLoading } = useGetFavoriteArticlesQuery()
  const [setFav] = useSetFavoriteArticleMutation()

  useEffect(() => {
    dispatch(setFavoriteArticles(favoriteArticlesList || []))
    setFavorite(Boolean(favoriteArticlesList?.find((i) => i.id === id)))
  }, [favoriteArticlesList, id])

  const [isFavorite, setFavorite] = useState<boolean>(false)

  const toggleFav = () => {
    if (!article) return
    try {
      setFavorite((prev) => !prev)
      setFav(id).then(() => {
        dispatch(toggleFavoriteArticles(article))
      })
    } catch (err) {
      console.warn(err)
    }
  }

  return isArticleLoading || isFetching ? (
    <>
      <div className={clsx("skeletonBlock", styles.titleLoading)} />
      <div className={clsx("skeletonBlock", styles.creatorLoading)} />
      <div className={clsx("skeletonBlock", styles.textLoading)} />
    </>
  ) : article ? (
    <div>
      <header className={styles.header}>
        <div className={styles.headerTop}>
          <h2 className={styles.title}>{article.name}</h2>
          {user && !isFavoriteLoading && id && <FavoriteBtn isActive={isFavorite} onClick={toggleFav} />}
        </div>

        <div className={styles.headerBot}>
          <Avatar
            img={article.creator?.avatar_id ? getApiUrl() + "/web/user/avatar/" + article.creator.avatar_id : ""}
            name={article.name}
            size={"xs"}
            className={styles.avatar}
          />
          <p>{article.creator.name}</p>

          <span className={styles.dot} />

          <p className={styles.date}>{moment(article.created_at).locale("ru").format("DD MMMM")}</p>

          <span className={styles.dot} />

          <span>{article.view_count} просмотров</span>
        </div>
      </header>

      <div className={styles.article} dangerouslySetInnerHTML={{ __html: article.text }} />

      {article?.tags && article.tags.length > 0 ? (
        <div className={styles.artTags}>
          {article.tags.map((tag) => (
            <Label key={tag.id} txt={tag.name} mode={"gray"} />
          ))}
        </div>
      ) : null}
    </div>
  ) : null
}

export default Article

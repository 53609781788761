// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReviewsLayout_titleBack__WsSzc {
  margin-bottom: 31px;
}
@media (min-width: 1200px) {
  .ReviewsLayout_titleBack__WsSzc {
    margin-bottom: 24px;
  }
}

.ReviewsLayout_subtitleLoader__jHs66 {
  height: 18px;
  width: 150px;
  border-radius: 4px;
  margin-bottom: 12px;
}

.ReviewsLayout_itemLoader__uv5IR {
  height: 219px;
  border-radius: 12px;
  margin-bottom: 12px;
}

.ReviewsLayout_noReviews__Zpmsx {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 114%;
  margin: auto;
  width: 100%;
}
@media (min-width: 1200px) {
  .ReviewsLayout_noReviews__Zpmsx {
    margin-top: 40px;
    position: initial;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ReviewsLayout/ReviewsLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EAHF;IAII,mBAAA;EAEF;AACF;;AACA;EACE,YAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;AAEF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAEF;;AACA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AAEF;AAAE;EARF;IASI,gBAAA;IACA,iBAAA;EAGF;AACF","sourcesContent":[".titleBack {\r\n  margin-bottom: 31px;\r\n\r\n  @media (min-width: 1200px) {\r\n    margin-bottom: 24px;\r\n  }\r\n}\r\n\r\n.subtitleLoader {\r\n  height: 18px;\r\n  width: 150px;\r\n  border-radius: 4px;\r\n  margin-bottom: 12px;\r\n}\r\n\r\n.itemLoader {\r\n  height: 219px;\r\n  border-radius: 12px;\r\n  margin-bottom: 12px;\r\n}\r\n\r\n.noReviews {\r\n  text-align: center;\r\n  font-size: 16px;\r\n  font-weight: 600;\r\n  line-height: 114%;\r\n  margin: auto;\r\n  width: 100%;\r\n\r\n  @media (min-width: 1200px) {\r\n    margin-top: 40px;\r\n    position: initial;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleBack": `ReviewsLayout_titleBack__WsSzc`,
	"subtitleLoader": `ReviewsLayout_subtitleLoader__jHs66`,
	"itemLoader": `ReviewsLayout_itemLoader__uv5IR`,
	"noReviews": `ReviewsLayout_noReviews__Zpmsx`
};
export default ___CSS_LOADER_EXPORT___;

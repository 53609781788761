import styles from "./MobileMenu.module.scss"
import clsx from "clsx"
import { WorkListIcon } from "../../icons/WorkListIcon"
import { DoubleCheckIcon } from "../../icons/DoubleCheckIcon"
import { SendIcon } from "../../icons/SendIcon"
import { BookOpenIconStroke } from "../../icons/BookOpenIconStroke"
import { ThreeDots } from "../../icons/ThreeDots"
import { useLocation } from "react-router-dom"
import { Fragment, memo } from "react"
import LocalizedLink from "../../hoc/LocalizedLink"
import { useDispatch } from "react-redux"
import { getOpenIsMoreModal, setOpenIsMoreModal } from "../../redux/slice/isMoreModal"
import { useAppSelector } from "../../hooks"

interface IMenuItem {
  name: string
  icon?: JSX.Element
  link?: string
  activeLinks?: string | string[]
  func?: () => void
  strokeIcon?: boolean
  circleIcon?: boolean
  isActive?: boolean
}

const MobileMenu = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const mobileMoreModalIsOpen = useAppSelector(getOpenIsMoreModal)

  const isActiveItem = (link: string | string[]): boolean | undefined => {
    if (!link || link.length < 1) return

    if (
      (typeof link === "string" && location.pathname.includes(link)) ||
      (Array.isArray(link) && link.includes(location.pathname))
    )
      return true
  }

  const menuList: IMenuItem[] = [
    {
      name: "Главная",
      icon: <WorkListIcon />,
      link: "/",
      func: () => {
        dispatch(setOpenIsMoreModal(false))
      },
      isActive: !mobileMoreModalIsOpen && isActiveItem(["/", "/orders", "/history"]),
    },
    { name: "Согласования", icon: <DoubleCheckIcon /> },
    { name: "Чаты", icon: <SendIcon /> },
    {
      name: "База знаний",
      icon: <BookOpenIconStroke />,
      link: "/articles",
      activeLinks: "/articles",
      strokeIcon: true,
      isActive: !mobileMoreModalIsOpen && isActiveItem("/articles"),
      func: () => {
        dispatch(setOpenIsMoreModal(false))
      },
    },
    {
      name: "Ещё",
      icon: <ThreeDots />,
      func: () => {
        dispatch(setOpenIsMoreModal(true))
      },
      circleIcon: true,
      isActive: mobileMoreModalIsOpen,
    },
  ]

  return (
    <div className={clsx(styles["mobile-menu"], "mobile-menu")}>
      {menuList.map((el, i) => (
        <Fragment key={i}>
          {el.link ? (
            <LocalizedLink
              to={el.link}
              className={clsx(styles["item"])}
              onClick={() => {
                if (el.func) el.func()
              }}
            >
              <div
                className={clsx(styles["item"], {
                  [styles["is-active"]]: el.isActive,
                })}
              >
                <div
                  className={clsx(styles["item__icon"], {
                    [styles["stroke-icon"]]: el.strokeIcon,
                    [styles["circle-icon"]]: el.circleIcon,
                  })}
                >
                  {el.icon}
                </div>
                <div className={clsx(styles["item__name"])}>{el.name}</div>
              </div>
            </LocalizedLink>
          ) : (
            <div
              className={clsx(styles["item"], {
                [styles["is-active"]]: el.isActive,
              })}
              onClick={() => {
                if (el.func) el.func()
              }}
            >
              <div
                className={clsx(styles["item__icon"], {
                  [styles["stroke-icon"]]: el.strokeIcon,
                  [styles["circle-icon"]]: el.circleIcon,
                })}
              >
                {el.icon}
              </div>
              <div className={clsx(styles["item__name"])}>{el.name}</div>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default memo(MobileMenu)

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoFindBtn_noFindBtn__VjtU9 {
  margin-top: 8px;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  width: 100%;
  height: 44px;
}
@media (min-width: 640px) {
  .NoFindBtn_noFindBtn__VjtU9 {
    margin-top: 16px;
    font-size: 20px;
  }
}
.NoFindBtn_noFindBtn_big__i-g3K {
  margin-top: 12px;
}
@media (min-width: 640px) {
  .NoFindBtn_noFindBtn_big__i-g3K {
    margin-top: 16px;
  }
}
@media (min-width: 640px) {
  .NoFindBtn_noFindBtn__big__hdCNK {
    height: 104px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/NoFindBtn/NoFindBtn.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EARF;IASI,gBAAA;IACA,eAAA;EAEF;AACF;AAAE;EACE,gBAAA;AAEJ;AAAI;EAHF;IAII,gBAAA;EAGJ;AACF;AAAE;EACE;IACE,aAAA;EAEJ;AACF","sourcesContent":[".noFindBtn {\n  margin-top: 8px;\n  font-size: 15px;\n  font-weight: 600;\n  line-height: 16px;\n  width: 100%;\n  height: 44px;\n\n  @media (min-width: 640px) {\n    margin-top: 16px;\n    font-size: 20px;\n  }\n\n  &_big {\n    margin-top: 12px;\n\n    @media (min-width: 640px) {\n      margin-top: 16px;\n    }\n  }\n\n  @media (min-width: 640px) {\n    &__big {\n      height: 104px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noFindBtn": `NoFindBtn_noFindBtn__VjtU9`,
	"noFindBtn_big": `NoFindBtn_noFindBtn_big__i-g3K`,
	"noFindBtn__big": `NoFindBtn_noFindBtn__big__hdCNK`
};
export default ___CSS_LOADER_EXPORT___;

import { FC, MouseEvent, useMemo } from "react"
import ButtonWrap from "../Button/ButtonWrap"
import clsx from "clsx"
import { ExitIcon } from "../../../icons/ExitIcon"
import { PlusIcon } from "../../../icons/PlusIcon"
import { CloseSquareIcon } from "../../../icons/CloseSquareIcon"
import { ArrowLeftIcon } from "../../../icons/ArrowLeftIcon"
import { ArrowDownIcon } from "../../../icons/ArrowDownIcon"
import { BookOpenIconStroke } from "../../../icons/BookOpenIconStroke"
import { ArrowLeftBoldIcon } from "../../../icons/ArrowLeftBoldIcon"
import styles from "./IconBtn.module.scss"

interface Props {
  icon: "exit" | "plus" | "close-square" | "back" | "back-bold" | "down" | "book" | JSX.Element
  href?: string
  isTargetBlank?: boolean
  type?: "button" | "submit" | "reset" | "span"
  onClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>) => void
  disabled?: boolean
  className?: string
  borderSize?: "sm" | "circle"
  mode?: "gray" | "white"
  size?: "sm"
  [key: string]: any
}

const IconBtn: FC<Props> = ({
  icon,
  href,
  isTargetBlank,
  type = "button",
  onClick,
  disabled,
  className,
  borderSize,
  mode,
  size,
  ...props
}) => {
  const iconEl = useMemo(() => {
    if (!icon) return null
    switch (icon) {
      case "exit":
        return <ExitIcon />
      case "plus":
        return <PlusIcon />
      case "close-square":
        return <CloseSquareIcon />
      case "back":
        return <ArrowLeftIcon />
      case "back-bold":
        return <ArrowLeftBoldIcon />
      case "down":
        return <ArrowDownIcon />
      case "book":
        return <BookOpenIconStroke />
      default:
        return icon
    }
  }, [icon])

  return (
    <ButtonWrap
      type={type}
      href={href}
      onClick={onClick}
      isTargetBlank={isTargetBlank}
      disabled={disabled}
      className={clsx(
        styles.btn,
        borderSize && styles["btn--border-" + borderSize],
        mode && styles["btn--" + mode],
        size && styles["btn--" + size],
        className,
      )}
      {...props}
    >
      {iconEl}
    </ButtonWrap>
  )
}

export default IconBtn

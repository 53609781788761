import React from "react"
import styles from "./LanguageModal.module.scss"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { languageModalOpen } from "../../../redux/slice/languageModal"
import { LANG_LIST } from "../../../utils/constants"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import { setMobileMenuIsHidden } from "../../../redux/slice/isMoreModal"

const LanguageModal: React.FC = () => {
  const { t, i18n } = useTranslation("translation", { keyPrefix: `interface` })

  const dispatch = useDispatch()
  const { openModal } = useSelector((state: any) => state.languageModal)

  const setOpen = (flag: boolean) => {
    dispatch(languageModalOpen({ flag: flag }))
  }

  // Функция выбора языка
  const setLanguage = (lang: string) => {
    setOpen(false)

    i18n.changeLanguage(lang)
  }

  return (
    <ModalPortal
      isOpen={openModal}
      setIsOpen={() => {
        dispatch(languageModalOpen({ flag: !openModal }))
      }}
      onClose={() => {
        dispatch(setMobileMenuIsHidden(false))
      }}
      className={"lang-modal"}
    >
      <div className={styles["lang-modal"]}>
        <div className={`modal-head`}>
          <div className={`modal-title`}>{t("interfaceLanguage")}</div>
          <div className={`modal-desc`}>{t("selectLangDesc")}</div>
          {/* <img src="/img/logo_staq_sm.svg" alt="" /> */}
        </div>
        {/* <div className={`modal-text`}>{t("selectLangText")}</div> */}
        <div className={`lang-list`}>
          {LANG_LIST.map((el, kk: number) => (
            <button key={kk} onClick={() => setLanguage(el.value)} className={styles["lang-btn"]}>
              <img src={`/img/lang/${el.value}.png`} alt="language" />
              <span>{el.text}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9 6L15 12L9 18" stroke="#4B5058" strokeWidth="2" />
              </svg>
            </button>
          ))}
        </div>
      </div>
    </ModalPortal>
  )
}

export default LanguageModal

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticleItemAccordion_item__2eyGC {
  position: relative;
  width: 100%;
  border-radius: 4px;
  color: #3D4149;
  padding-left: 50px;
}
@media (hover: hover) {
  .ArticleItemAccordion_item__2eyGC:hover {
    background-color: #FAFAFA;
  }
}
.ArticleItemAccordion_item--is-active__rw1ae {
  background-color: #FAFAFA;
  color: #262626;
  font-weight: 500;
}
.ArticleItemAccordion_item--is-active__rw1ae::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  width: 3px;
  background-color: var(--accent-color);
  border-radius: 4px;
}
.ArticleItemAccordion_item__2eyGC a {
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
}
.ArticleItemAccordion_item__2eyGC svg {
  color: var(--orange-500);
  flex-shrink: 0;
  margin-left: auto;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Articles/ArticleItemAccordion/ArticleItemAccordion.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;AACF;AACE;EACE;IACE,yBAAA;EACJ;AACF;AAEE;EACE,yBAAA;EACA,cAAA;EACA,gBAAA;AAAJ;AAEI;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,UAAA;EACA,qCAAA;EACA,kBAAA;AAAN;AAIE;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AAFJ;AAKE;EACE,wBAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;AAHJ","sourcesContent":[".item {\r\n  position: relative;\r\n  width: 100%;\r\n  border-radius: 4px;\r\n  color: #3D4149;\r\n  padding-left: 50px;\r\n\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      background-color: #FAFAFA;\r\n    }\r\n  }\r\n\r\n  &--is-active {\r\n    background-color: #FAFAFA;\r\n    color: #262626;\r\n    font-weight: 500;\r\n\r\n    &::before {\r\n      content: '';\r\n      position: absolute;\r\n      left: 2px;\r\n      top: 2px;\r\n      bottom: 2px;\r\n      width: 3px;\r\n      background-color: var(--accent-color);\r\n      border-radius: 4px;\r\n    }\r\n  }\r\n\r\n  a {\r\n    width: 100%;\r\n    min-height: 32px;\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n\r\n  svg {\r\n    color: var(--orange-500);\r\n    flex-shrink: 0;\r\n    margin-left: auto;\r\n    margin-right: 10px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `ArticleItemAccordion_item__2eyGC`,
	"item--is-active": `ArticleItemAccordion_item--is-active__rw1ae`
};
export default ___CSS_LOADER_EXPORT___;

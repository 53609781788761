import React, { useEffect, useMemo } from "react"
import styles from "./ServicesList.module.scss"
import ServiceEl from "./ServiceEl"
import NoFindBtn from "../NoFindBtn/NoFindBtn"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { languageModalOpen } from "../../redux/slice/languageModal"
import { useAppSelector } from "../../hooks"
import { selectAuth, selectIsAllowProposeNewServiceMain, updateIsAllowNewServiceCategory } from "../../redux/slice/auth"
import moment from "moment"
import { langWithCurrentMomentCode, servicesBgList } from "../../utils/helpers"
import useWindowSize from "../../hooks/useWindowSize"
import { IServiceItem } from "../../types/content"
import clsx from "clsx"

interface Props {
  setAuthModal?: any
  setAuthModalType?: any
}

//TODO: remove
const sample = [
  {
    description: "",
    id: "8d27c460-d2f6-416d-a6f8-abdef7c08207",
    image_id: "527f50cb-9b00-4e73-b860-eb63a8199c89",
    name: "Services for new design",
  },
  {
    description: "Repair and maintenance of various equipment",
    id: "fd762e74-7042-4a90-81ff-4ba82cbeff93",
    image_id: "1ec98675-f991-4867-a723-898cbfb7a51d",
    name: "Repair of equipment",
  },
  {
    id: "77611fd8-b107-48d3-b127-f59eea6e0653",
    name: "Knowledge Base 2.0",
    description: "",
    image_id: "b6eb1b53-35c1-4f45-8f4c-6f9aa9a9cb5e",
  },
  {
    id: "f312b627-be8e-42db-8f76-c27dc65b8709",
    name: "Knowledge Base 3.0",
    description: "",
    image_id: "fa16ef23-031f-4caf-adde-e352842d5b9c",
  },
  {
    id: "58e67965-0985-4671-a92d-a6ae7ccec47b",
    name: "Knowledge Base 4.0",
    description: "",
    image_id: "0e898f12-7c3d-4feb-b1f6-4ad57c207dd7",
  },
  {
    id: "45cdf6a1-1461-41d2-82a6-9ef3331301e0",
    name: "Knowledge base",
    description: "",
    image_id: "081b8447-b076-4fac-a63d-f2f69465cd05",
  },
  {
    id: "ac258ea0-1855-4684-9a7b-dfabfacb3d38",
    name: "Test tile category",
    description: "",
    image_id: "6e7e986c-ac6d-4e63-9c2a-6a4bae73a880",
  },
  {
    id: "a57911e8-a630-485f-8ef9-b8aac7f333fa",
    name: "Tile Test 2.0",
    description: "",
    image_id: "c1a64ede-40f3-44ce-93ed-5be43c8084cd",
  },
  {
    id: "dfd13663-1c6f-429e-b482-c02d747ede84",
    name: "Remote Desktop",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "1",
    name: "Remote Desktop1",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "2",
    name: "Remote Desktop2",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "3",
    name: "Remote Desktop3",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "4",
    name: "Remote Desktop4",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "5",
    name: "Remote Desktop5",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "6",
    name: "Remote Desktop6",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "7",
    name: "Remote Desktop7",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "8",
    name: "Remote Desktop8",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "9",
    name: "Remote Desktop9",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "10",
    name: "Remote Desktop10",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "11",
    name: "Remote Desktop11",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "12",
    name: "Remote Desktop12",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "13",
    name: "Remote Desktop13",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "14",
    name: "Remote Desktop14",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "15",
    name: "Remote Desktop15",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "16",
    name: "Remote Desktop16",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "17",
    name: "Remote Desktop17",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "18",
    name: "Remote Desktop18",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "19",
    name: "Remote Desktop19",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "20",
    name: "Remote Desktop20",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "21",
    name: "Remote Desktop21",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "22",
    name: "Remote Desktop22",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "23",
    name: "Remote Desktop23",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "24",
    name: "Remote Desktop24",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "25",
    name: "Remote Desktop25",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "26",
    name: "Remote Desktop26",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "27",
    name: "Remote Desktop27",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "28",
    name: "Remote Desktop28",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "29",
    name: "Remote Desktop29",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
  {
    id: "30",
    name: "Remote Desktop30",
    description: "",
    image_id: "356c6ea8-6cf7-4317-9aad-d2589d1667c7",
  },
]

interface IItem extends IServiceItem {
  bg: string
}

const ServicesList: React.FC<Props> = ({ setAuthModal, setAuthModalType }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const currentLang = localStorage.getItem("i18nextLng")

  useEffect(() => {
    if (currentLang) {
      moment.locale(langWithCurrentMomentCode.get(currentLang))
    } else {
      moment.locale("en")
    }
  }, [currentLang])

  useEffect(() => {
    dispatch(updateIsAllowNewServiceCategory(false))
  }, [])

  const { serviceList, serviceListLoading } = useAppSelector((state) => state.main)

  const isAllowProposeNewServiceMain = useAppSelector(selectIsAllowProposeNewServiceMain)
  const { isDesktop } = useWindowSize()

  const list = useMemo(() => {
    if (!serviceList?.length) return
    let isFive = true
    const result = serviceList.reduce((accumulator: IItem[][], currentValue, index) => {
      if (!isDesktop) {
        if (index % 4 === 0) accumulator.push([])
      } else {
        if (isFive) {
          if (index === 0) accumulator.push([])
          if ((index + 3) % 8 === 0) {
            accumulator.push([])
            if (index !== 0) isFive = false
          }
        } else {
          if (index % 8 === 0) {
            accumulator.push([])
            isFive = true
          }
        }
      }

      accumulator[accumulator.length - 1].push({ ...currentValue, bg: servicesBgList[index % 12] })
      return accumulator
    }, [])

    let sufNum5 = 0
    let sufNum3 = 0
    return result.map((item, index) => {
      const num = index % 2 === 0 ? 5 : 3
      let fullNum = null
      if (isDesktop) {
        if (num === 5) {
          sufNum5 = sufNum5 >= 3 ? 1 : ++sufNum5
        }
        if (num === 3) {
          sufNum3 = sufNum3 >= 3 ? 1 : ++sufNum3
        }
        fullNum = num + "-" + (num === 5 ? sufNum5 : sufNum3)
      }

      let lastClass = null
      if (index + 1 === result.length) {
        lastClass = styles["row--last-" + (isDesktop ? "" : "mob-") + item.length]
      }

      return (
        <div key={index} className={clsx(styles.row, !lastClass && fullNum && styles[`row--${fullNum}`], lastClass)}>
          {item?.map((el) => {
            return (
              <ServiceEl
                key={el.id}
                id={el.id}
                title={el?.name}
                subtitle={el?.description}
                background={el.bg}
                img={el?.image_id}
              />
            )
          })}
        </div>
      )
    })
  }, [sample, serviceList, isDesktop])

  return (
    <div className={styles.serviceList}>
      {isDesktop && (
        <h4
          className={`${styles.serviceList__title} ${
            serviceListLoading ? `selectBack ${styles.serviceList__title_load}` : ""
          }`}
        >
          {t("services")}
        </h4>
      )}

      <div className={styles.serviceList__list}>
        {serviceListLoading && !serviceList.length ? (
          <div className={styles.gridLoading}>
            <div className={`selectBack ${styles.elLoading}`} />
            <div className={`selectBack ${styles.elLoading}`} />
            <div className={`selectBack ${styles.elLoading}`} />
            <div className={`selectBack ${styles.elLoading}`} />
            <div className={`selectBack ${styles.elLoading}`} />
          </div>
        ) : (
          <div className={styles.grid}>{list}</div>
        )}
      </div>

      {!isDesktop && <NoFindBtn isLoading={serviceListLoading} />}
    </div>
  )
}

export default ServicesList

import clsx from "clsx"
import Paper from "../Assets/Paper/Paper"
import ArticlePrevItem from "../Articles/ArticlePrevItem/ArticlePrevItem"
import { useGetPopularArticlesQuery } from "../../redux/api/articles"
import styles from "./ArticlesMaybe.module.scss"

const ArticlesMaybe = ({ className }: { className?: string }) => {
  const { data: popularArticles, isLoading } = useGetPopularArticlesQuery()

  return isLoading ? (
    <div className={clsx("skeletonBlock", styles.loader)} />
  ) : (
    <Paper className={clsx(styles.block, className)}>
      <h4 className={styles.title}>Возможно вам поможет</h4>
      <ul className={styles.list}>
        {popularArticles?.popular?.slice(0, 3).map((item) => {
          return (
            <li key={item.id} className={styles.li}>
              <ArticlePrevItem key={item.id} {...item} />
            </li>
          )
        })}
      </ul>
    </Paper>
  )
}

export default ArticlesMaybe

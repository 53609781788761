import { useEffect, useMemo, useState } from "react"
import ArticleListAccordion from "../ArticleListAccordion/ArticleListAccordion"
import ArticleItemAccordion from "../ArticleItemAccordion/ArticleItemAccordion"
import { TreeUpIcon } from "../../../icons/TreeUpIcon"
import { TreeDownIcon } from "../../../icons/TreeDownIcon"
import LocalizedLink from "../../../hoc/LocalizedLink"
import ScrollBlock from "../../Assets/ScrollBlock"
import { IArticle } from "../../../types/content"
import { useAppSelector } from "../../../hooks"
import { selectArticles } from "../../../redux/slice/articles"
import styles from "./ArticlesList.module.scss"
import useWindowSize from "../../../hooks/useWindowSize"

interface IExArticle extends IArticle {
  children: IArticle[]
}

function insertDataByIdRecursive(arr: any, id: string, dataToInsert: IExArticle) {
  for (const item of arr) {
    if (item.id === id) {
      item.children.push(dataToInsert)
      return true
    } else if (item.children && item.children.length > 0) {
      const isInserted = insertDataByIdRecursive(item.children, id, dataToInsert)
      if (isInserted) return true
    }
  }
  return false
}

const ArticlesList = ({ articles }: { articles: IArticle[] }) => {
  const { favoriteArticles } = useAppSelector(selectArticles)
  const { isDesktop } = useWindowSize()

  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined)
  const [isGlobalOpen, setGlobalOpen] = useState<boolean>(false)
  const [isGlobalOpenAllowed, setGlobalOpenAllowed] = useState<boolean>(false)

  const articlesList = useMemo(() => {
    const output: IExArticle[] = []

    articles.forEach((article) => {
      if (!article.parent_id) {
        output.push({ ...article, children: [] })
      } else {
        insertDataByIdRecursive(output, String(article.parent_id), { ...article, children: [] })
      }
    })

    const recursiveInsertArticleContent = (article: any) => {
      if (article?.children?.length) {
        return (
          <ArticleListAccordion
            key={article.id}
            name={article.text}
            href={article.id}
            isGlobalOpenAllowed={isGlobalOpenAllowed}
            isGlobalOpen={isGlobalOpen}
          >
            {article.children.map((i: any) => {
              return recursiveInsertArticleContent(i)
            })}
          </ArticleListAccordion>
        )
      } else {
        return (
          <ArticleItemAccordion
            key={article.id}
            txt={article.text}
            href={article.id}
            isFavorite={Boolean(favoriteArticles?.find((i) => i.id === article.id))}
          />
        )
      }
    }

    return (
      <div className={styles.inner}>
        {output?.map((article, index) => {
          return (
            <ArticleListAccordion
              key={index}
              name={article.text}
              href={article.id}
              isGlobalOpenAllowed={isGlobalOpenAllowed}
              isGlobalOpen={isGlobalOpen}
            >
              {article?.children?.map((childArticle: any) => recursiveInsertArticleContent(childArticle))}
            </ArticleListAccordion>
          )
        })}
      </div>
    )
  }, [articles, isGlobalOpen, isGlobalOpenAllowed, favoriteArticles])

  const globalTrigger = () => {
    setGlobalOpenAllowed(true)
    setGlobalOpen((prev) => !prev)
    setTimeout(() => {
      setGlobalOpenAllowed(false)
    }, 200)
  }

  useEffect(() => {
    function setMax() {
      const windowHeight = window.innerHeight
      const headerHeight = (document.querySelector(".header") as HTMLDivElement)?.offsetHeight
      // высота экрана - высота хедера - отступы у aside сверху/снизу - высота кнопки Назад(на Главную) - хедер aside
      setMaxHeight(windowHeight - headerHeight - 50 - 72 - 44)
    }
    setMax()

    window.addEventListener("resize", setMax)
    return () => window.removeEventListener("resize", setMax)
  }, [])

  return (
    <div className={styles.aside}>
      <header className={styles.header}>
        <LocalizedLink to={"/articles"} className={styles.link}>
          {isDesktop ? "На главную" : "Все статьи"}
        </LocalizedLink>
        <button onClick={globalTrigger} className={styles.btn}>
          {isGlobalOpen ? <TreeUpIcon /> : <TreeDownIcon />}
        </button>
      </header>
      <ScrollBlock hideTracksWhenNotNeeded autoHeightMax={maxHeight}>
        {articlesList}
      </ScrollBlock>
    </div>
  )
}

export default ArticlesList

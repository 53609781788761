import { FC, useEffect, useState } from "react"
import NoFindBtn from "../NoFindBtn/NoFindBtn"
import { useTranslation } from "react-i18next"
import { appHeight } from "../../utils/helpers"
import { useLocation, useParams } from "react-router-dom"
import { useAppSelector } from "../../hooks"
import { languageModalOpen } from "../../redux/slice/languageModal"
import { getCookie } from "../../utils/cookies"
import { USER_TOKEN_COOKIE } from "../../utils/constants"
import { registerSW } from "../../utils/serviceWorkerRegistration"
import FooterSupport from "./FooterSupport/FooterSupport"
import clsx from "clsx"
import { selectParams } from "../../redux/slice/params"
import { selectIsAllowNewServiceCategory, selectIsAllowProposeNewServiceMain } from "../../redux/slice/auth"
import { useGetCompanyLogoQuery } from "../../redux/api/auth"
import LocalizedLink from "../../hoc/LocalizedLink"
import useWindowSize from "../../hooks/useWindowSize"
import styles from "./footer.module.scss"

const Footer = ({ noFindBtn }: { noFindBtn?: boolean }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const params = useParams()
  const location = useLocation()
  const { isDesktop } = useWindowSize()

  const { isSearchVal } = useAppSelector(selectParams)
  const isAllowProposeNewServiceMain = useAppSelector(selectIsAllowProposeNewServiceMain)
  const isAllowNewServiceCategory = useAppSelector(selectIsAllowNewServiceCategory)
  const { serviceListLoading } = useAppSelector((state) => state.main)
  const { data: logoCompany } = useGetCompanyLogoQuery()
  // const currentLang = params?.locale
  // const token = getCookie(USER_TOKEN_COOKIE)
  // const dispatch = useAppDispatch()

  const [isNoFindBtnShown, setNoFindBtnShown] = useState<boolean>(false)

  useEffect(() => {
    appHeight()

    // Запись в кэш изображений
    const imgs = document.getElementsByTagName("img")
    const imgsSrc: string[] = []
    // console.log(imgs);
    for (let i = 0; i < imgs.length; i++) {
      const src = imgs[i].getAttribute("src") ?? ""
      if (!imgsSrc.includes(src)) imgsSrc.push(src)
    }
    registerSW()
    if (typeof caches !== "undefined") {
      caches?.open("my-cache-v1")?.then(function (cache) {
        return cache.addAll(imgsSrc)
      })
    }
    //

    //   // Выводить модалку выбора языка если нет выбранного языка в url и юзер не залогинен
    //   // setTimeout(()=>i18n.changeLanguage(currentLang ? currentLang : "en"), 10)
    //   i18n.changeLanguage(currentLang ? currentLang : "en")
    //   if ((!currentLang || currentLang === "dev") && !token) {
    //     dispatch(languageModalOpen({ flag: true }))
    //   }

    window.addEventListener("resize", appHeight)
    return () => window.removeEventListener("resize", appHeight)
  }, [])

  useEffect(() => {
    if (noFindBtn) {
      setNoFindBtnShown(false)
      return
    }
    if (["/history", "/orders"].includes(location.pathname)) {
      setNoFindBtnShown(false)
      return
    }
    if (location.pathname === "/") {
      setNoFindBtnShown(isSearchVal)
      return
    }
    if (params?.id) {
      setNoFindBtnShown(isAllowProposeNewServiceMain || isAllowNewServiceCategory)
    }
  }, [isSearchVal, isAllowProposeNewServiceMain, isAllowNewServiceCategory, params, location])

  return !isDesktop && location.pathname === "/" && serviceListLoading ? null : (
    <footer className={clsx("footer", styles.footer)}>
      {!isDesktop && isNoFindBtnShown && <NoFindBtn />}
      <div className={`footer__wp ${styles.footer__wp}`}>
        <div className={styles.footer__info}>
          {/*<p>{t("digitalPlatf")}</p>*/}
          {logoCompany?.data?.length !== 0 && (
            <LocalizedLink to={"/"} className={`footer__logoWrap ${styles.footer__logoWrap}`}>
              <span dangerouslySetInnerHTML={{ __html: logoCompany }} />
            </LocalizedLink>
          )}
          <p>В случае возникновения каких-либо вопросов обращайтесь в службу технической поддержки.</p>
        </div>
        <FooterSupport />
      </div>
    </footer>
  )
}

export default Footer

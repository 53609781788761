export const DoubleCheckIcon = () => {
  return (
    <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7764 0.458055C19.0757 0.702865 19.1198 1.14388 18.875 1.44309L12.0013 9.84426C11.4113 10.5653 10.351 10.6772 9.62356 10.0952L6.22923 7.37977C5.92735 7.13826 5.8784 6.69776 6.11991 6.39587C6.36141 6.09399 6.80192 6.04504 7.1038 6.28655L10.4981 9.00202C10.6265 9.10472 10.8136 9.08497 10.9177 8.95773L17.7914 0.556559C18.0362 0.257348 18.4772 0.213246 18.7764 0.458055Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48596 5.78929L13.0419 1.44309C13.2868 1.14388 13.2426 0.702865 12.9434 0.458056C12.6442 0.213246 12.2032 0.257348 11.9584 0.556559L8.41041 4.89299L9.48596 5.78929ZM6.31965 9.65923L5.24409 8.76294L5.06545 8.98128C4.96365 9.1057 4.78187 9.12777 4.65326 9.03131L2.08684 7.10649C1.77756 6.87453 1.3388 6.93721 1.10684 7.24649C0.874879 7.55577 0.937559 7.99453 1.24684 8.22649L3.81326 10.1513C4.54204 10.6979 5.57213 10.5729 6.14899 9.86782L6.31965 9.65923Z"
      />
    </svg>
  )
}

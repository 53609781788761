// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FavoriteBtn_btn__NlUXo {
  flex-shrink: 0;
  color: var(--gray-100);
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);
}
@media (hover: hover) {
  .FavoriteBtn_btn__NlUXo:hover {
    color: var(--orange-500);
  }
}
.FavoriteBtn_btn--is-active__WGKss {
  color: var(--orange-500);
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/FavoriteBtn/FavoriteBtn.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,6BAAA;AACF;AACE;EACE;IACE,wBAAA;EACJ;AACF;AAEE;EACE,wBAAA;AAAJ","sourcesContent":[".btn {\r\n  flex-shrink: 0;\r\n  color: var(--gray-100);\r\n  width: 22px;\r\n  height: 22px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  transition: var(--transition);\r\n\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      color: var(--orange-500);\r\n    }\r\n  }\r\n\r\n  &--is-active {\r\n    color: var(--orange-500);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `FavoriteBtn_btn__NlUXo`,
	"btn--is-active": `FavoriteBtn_btn--is-active__WGKss`
};
export default ___CSS_LOADER_EXPORT___;

import { FC, useMemo } from "react"
import clsx from "clsx"
import LabelCount from "../../Assets/LabelCount/LabelCount"
import Avatar from "../../Assets/Avatar/Avatar"
import { IChatInfo } from "../../../types/content"
import moment from "moment"
import { getApiUrl } from "../../../utils/getApiUrl"
import styles from "./MessagesAsideItem.module.scss"

interface Props extends IChatInfo {
  isActive?: boolean
  onClick: (val: string) => void
  className?: string
}

const MessagesAsideItem: FC<Props> = ({
  id,
  name,
  image,
  is_private,
  unreaded_messages,
  last_message,
  isActive,
  onClick,
  className,
}) => {
  const time = useMemo(() => {
    const date = last_message?.created_at || last_message?.readed_at
    if (!date) return null
    const isToday = new Date().toDateString() === new Date(date).toDateString()
    return isToday ? moment(date).format("kk:mm") : new Date(date).toLocaleDateString()
  }, [last_message?.created_at, last_message?.readed_at])

  const text = useMemo(() => {
    if (!last_message?.text && !last_message?.with_attachments && !last_message?.with_photos) {
      return null
    }
    if (last_message?.text) return last_message.text
    if (last_message?.with_attachments || last_message?.with_photos) {
      return <img className={styles.txtImg} src={"/img/emptyPhoto.jpg"} alt={""} />
    }
  }, [last_message?.text])

  return (
    <button
      type={"button"}
      className={clsx(styles.item, isActive && styles["item--is-active"], className)}
      onClick={() => {
        onClick(id)
      }}
    >
      <div className={clsx(styles.imgWrap)}>
        <Avatar
          img={image ? getApiUrl() + (is_private ? "/web/user/avatar/" : "/public/photo/preview/") + image : ""}
          name={name}
          isOrder={!is_private}
          className={clsx(styles.avatar, !image && styles["avatar--is-empty"])}
        />
        <LabelCount count={unreaded_messages} size={"sm"} className={styles.count} />
      </div>
      <div className={styles.content}>
        <h6 className={styles.title}>
          <span className={styles.name}>{name}</span>
          {time && <span className={styles.time}>{time}</span>}
        </h6>
        {text && <p className={styles.txt}>{text}</p>}
      </div>
    </button>
  )
}

export default MessagesAsideItem

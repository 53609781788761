// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticlesMaybe_block__Xranr .paper__main {
  padding-top: 15px;
}

.ArticlesMaybe_title__E5i5v {
  margin-bottom: 27px;
}

.ArticlesMaybe_list__IlObm {
  list-style-type: none;
}

.ArticlesMaybe_li__CYryp {
  margin-bottom: 24px;
}
.ArticlesMaybe_li__CYryp:last-child {
  margin-bottom: 0;
}

.ArticlesMaybe_loader__0EvN5 {
  height: 240px;
  border-radius: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/ArticlesMaybe/ArticlesMaybe.module.scss"],"names":[],"mappings":"AAGM;EACE,iBAAA;AAFR;;AAQA;EACE,mBAAA;AALF;;AAQA;EACE,qBAAA;AALF;;AAQA;EACE,mBAAA;AALF;AAOE;EACE,gBAAA;AALJ;;AASA;EACE,aAAA;EACA,mBAAA;AANF","sourcesContent":[".block {\r\n  :global {\r\n    .paper {\r\n      &__main {\r\n        padding-top: 15px;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n.title {\r\n  margin-bottom: 27px;\r\n}\r\n\r\n.list {\r\n  list-style-type: none;\r\n}\r\n\r\n.li {\r\n  margin-bottom: 24px;\r\n\r\n  &:last-child {\r\n    margin-bottom: 0;\r\n  }\r\n}\r\n\r\n.loader {\r\n  height: 240px;\r\n  border-radius: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `ArticlesMaybe_block__Xranr`,
	"title": `ArticlesMaybe_title__E5i5v`,
	"list": `ArticlesMaybe_list__IlObm`,
	"li": `ArticlesMaybe_li__CYryp`,
	"loader": `ArticlesMaybe_loader__0EvN5`
};
export default ___CSS_LOADER_EXPORT___;

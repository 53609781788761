import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import IconBtn from "../../Assets/IconBtn/IconBtn"
import OrderDescription from "../../OrderDescription/OrderDescription"
import { IChatInfo, IChatMessage, ILastMessage, IOrderReview } from "../../../types/content"
import Button from "../../Assets/Button/Button"
import { useTranslation } from "react-i18next"
import OrderCallbackModal from "../OrderInfoModal/OrderCallbackModal"
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import clsx from "clsx"
import Chat from "../../Chat/Chat"
import { addAllMessage, setAllMessage } from "../../../redux/slice/main"
import { useDispatch } from "react-redux"
import Search from "../../Search/Search"
import MessagesAsideList from "./MessagesAsideList"
import _debounce from "lodash/debounce"
import MessagesModalUsers from "./MessagesModalUsers"
import { useCreateChatMutation, useLazyGetChatListQuery, useLazyGetChatMessagesQuery } from "../../../redux/api/chat"
import styles from "./MessagesModal.module.scss"
import { useLazyGetServiceOrderQuery } from "../../../redux/api/content"
import { IServiceOrder } from "../../../types/orderTypes"
import { removeActiveOrdersCount, selectAuth } from "../../../redux/slice/auth"
import { useAppSelector } from "../../../hooks"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  firstChatId?: string
}

const LIMIT_CHAT_LIST = 14

const MessagesModal: FC<Props> = ({ open, setOpen, firstChatId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const loaderRef = useRef<HTMLDivElement>(null)

  const { user } = useAppSelector(selectAuth)

  const [getChatMessages] = useLazyGetChatMessagesQuery()
  const [getChatList, { isUninitialized, isFetching, isLoading }] = useLazyGetChatListQuery()
  const [getOrder] = useLazyGetServiceOrderQuery()
  const [createChat] = useCreateChatMutation()

  const isFirstLoadMess = useRef<any>(false)
  const [isInfoOpen, setInfoOpen] = useState<boolean>(false)
  const [isCanceledModal, setCanceledModal] = useState<boolean>(false)

  const [loadingChatList, setLoadingChatList] = useState<boolean>(true)
  const [loadingChat, setLoadingChat] = useState<boolean>(true)
  const [loadingOrderInfo, setLoadingOrderInfo] = useState<boolean>(true)

  const [searchChat, setSearchChat] = useState<string>("")
  const [chatList, setChatList] = useState<IChatInfo[]>([])
  const [order, setOrder] = useState<IServiceOrder | null>(null)
  const [activeChatId, setActiveChatId] = useState<string>("")
  const [activeOrderId, setActiveOrderId] = useState<string | null>("")
  const [offset, setOffset] = useState<number>(LIMIT_CHAT_LIST)
  const [isAllChats, setAllChats] = useState<boolean>(false)
  const [unreadMessList, setUnreadMessList] = useState<string[]>([])
  const [isFirstNoChats, setFirstNoChats] = useState<boolean>(false)

  const { allowScroll } = useScrollBlock()

  const setInitialChats = async () => {
    try {
      const list = await getChatList({ limit: LIMIT_CHAT_LIST })
      if (!list?.data?.aDialogs?.length) {
        setAllChats(true)
        setFirstNoChats(true)
        return
      }
      if (list?.data?.bIsEnd) setAllChats(true)

      let firstChat = list.data.aDialogs[0]

      if (firstChatId) {
        const currentChat = list.data.aDialogs.find((i) => i.id === firstChatId)
        if (currentChat) firstChat = currentChat
      }

      setChatList(list.data.aDialogs)
      setActiveChatId(firstChat.id)
      setActiveOrderId(firstChat.order_id)
      setLoadingChatList(false)

      const chat = await getChatMessages(firstChat.id)
      if (!chat?.data) return

      isFirstLoadMess.current = true
      dispatch(setAllMessage(chat.data.aMessages))
      setLoadingChat(false)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!open) {
      setInfoOpen(false)
      setLoadingChat(true)
      return
    }
    void setInitialChats()
  }, [open])

  const updateChatList = (event: any) => {
    //TODO: не приходит по сокетам
    console.log("%c event2 changeDialogList: ", "font-size: 16px; font-weight: 700; color: red; ", event, "")
    const existedChat = chatList.find((i) => i.id === event.id)
    if (!existedChat) setChatList((prev) => [event, ...prev])
    setFirstNoChats(false)
    setLoadingChatList(false)
    isFirstLoadMess.current = true
  }
  const updateChatListWithLastMess = (event: ILastMessage, chat_id: string) => {
    setChatList((prev) => {
      const index = prev.findIndex((i) => i.id === event.chat_dialog_id)
      const clone = [...prev]
      if (index !== 0 && !index) return prev
      const isNewUnread = chat_id && event.chat_dialog_id !== chat_id
      const unread = isNewUnread ? { unreaded_messages: clone[index].unreaded_messages + 1 } : {}
      clone[index] = { ...clone[index], last_message: event, ...unread }
      return clone
    })
  }

  useEffect(() => {
    if (isUninitialized) return
    if (typeof window.Echo === "undefined") return
    if (!activeChatId) return
    const channel = window.Echo.private(`privateUser.${user.id}`)
    channel?.listen(".changeDialogList", updateChatList)
    channel?.listen(".newMessageInDialog", (event: ILastMessage) => {
      updateChatListWithLastMess(event, activeChatId)
    })
    return () => {
      channel.stopListening(`.newMessageInDialog`)
      channel.stopListening(`.changeDialogList`)
    }
  }, [isUninitialized, activeChatId])

  // Обновление счётчика непрочитанных сообщений, когда отправляется запрос markReadedMessage
  useEffect(() => {
    if (!unreadMessList.length) return
    setChatList((prev) => {
      const index = prev.findIndex((i) => i.id === activeChatId)
      const clone = [...prev]
      if (index !== 0 && !index) return prev
      let newUnreadMess = clone[index].unreaded_messages - unreadMessList.length
      if (newUnreadMess < 0) newUnreadMess = 0
      clone[index] = { ...clone[index], unreaded_messages: newUnreadMess }
      return clone
    })
  }, [unreadMessList, activeChatId])

  // Очищаем список id прочитанных сообщений, при смене чата
  useEffect(() => {
    setUnreadMessList([])
  }, [activeChatId])

  const debounceFn = useCallback(
    _debounce((str: string) => {
      if (!isFirstLoadMess.current) return
      setChatList([])
      setLoadingChatList(true)
      getChatList({ search: str, limit: LIMIT_CHAT_LIST, offset: 0 }).then(({ data }) => {
        setChatList(data?.aDialogs || [])
        setLoadingChatList(false)
        setOffset(LIMIT_CHAT_LIST)
        setAllChats(data?.bIsEnd || false)
      })
    }, 500),
    [],
  )

  useEffect(() => {
    debounceFn(searchChat)
  }, [searchChat])

  const callbackSearch = () => {
    if (isAllChats) return
    setLoadingChatList(true)
    getChatList({ search: searchChat, limit: LIMIT_CHAT_LIST, offset: offset }).then(({ data }) => {
      if (!data?.aDialogs) {
        setLoadingChatList(false)
        setAllChats(true)
        return
      }
      setChatList((prev) => [...prev, ...data.aDialogs])
      setLoadingChatList(false)
      setAllChats(data?.bIsEnd || false)
      setOffset((prev) => prev + LIMIT_CHAT_LIST)
    })
  }

  const clickChat = (chatId: string) => {
    setInfoOpen(false)
    setLoadingChat(true)
    setActiveChatId(chatId)
    const currentChat = chatList.find((i) => i.id === chatId)
    setActiveOrderId(currentChat?.order_id || null)
    getChatMessages(chatId).then((res) => {
      if (res?.data?.aMessages) {
        dispatch(setAllMessage(res.data.aMessages))
        setLoadingChat(false)
      }
    })
  }

  const toggleInfoOrder = () => {
    const currentChat = chatList.find((i) => i.id === activeChatId)
    if (!currentChat?.order_id) return
    setLoadingOrderInfo(true)
    setInfoOpen((prev) => !prev)
    getOrder(currentChat.order_id).then(({ data }) => {
      if (data) setOrder(data)
      setLoadingOrderInfo(false)
    })
  }

  const handlerCreateChat = (val: string) => {
    if (!val) return
    createChat({ type: "users", id: val })
      .unwrap()
      .then((res) => {
        if (!res) return
        const existedChat = chatList.find((i) => i.id === res.id)
        if (!existedChat) {
          setChatList((prev) => [res, ...prev])
          clickChat(res.id)
          setFirstNoChats(false)
          setLoadingChatList(false)
          isFirstLoadMess.current = true
        }
      })
  }

  return (
    <>
      <ModalPortal
        isOpen={open}
        setIsOpen={setOpen}
        className={styles.modal}
        header={
          <>
            <div className={clsx(styles.headerSlice, styles.headerSliceAside)}>
              <h2 className={"modal__title"}>{t("chats")}</h2>
              <MessagesModalUsers className={styles.headerChatWrap} onClick={handlerCreateChat} />
            </div>
            <div
              className={clsx(
                styles.headerSlice,
                styles.headerSliceMain,
                isInfoOpen && styles["headerSliceMain--is-info-open"],
              )}
            >
              <h2 className={clsx("modal__title", styles.titleName)}>
                {chatList.find((i) => i.id === activeChatId)?.name}
              </h2>
              {activeOrderId && (
                <IconBtn
                  icon={"close-square"}
                  borderSize={"sm"}
                  mode={"gray"}
                  size={"sm"}
                  className={clsx(styles.btnInfo, isInfoOpen && styles["btnInfo--is-open"])}
                  onClick={toggleInfoOrder}
                />
              )}
              <div className={styles.separator} />
            </div>
          </>
        }
      >
        <div className={styles.main}>
          <aside className={styles.aside}>
            <Search searchVal={searchChat} setSearchVal={setSearchChat} className={styles.search} />
            {(!isUninitialized && chatList?.length > 0) || isLoading || isFetching ? (
              <MessagesAsideList
                list={chatList}
                activeChatId={activeChatId}
                isLoading={loadingChatList}
                callbackSearch={callbackSearch}
                onClick={clickChat}
              />
            ) : searchChat && !isUninitialized && !isFetching && !chatList.length ? (
              <p className={styles.noChatList}>{t("objNotFound")}</p>
            ) : isFirstNoChats ? (
              <p className={styles.noChatList}>{t("noChatsYet")}</p>
            ) : null}

            <div ref={loaderRef} />
          </aside>

          {loadingChat ? (
            <div className={clsx(styles.chatLoader, "skeletonBlock")} />
          ) : (
            <Chat
              className={styles.chat}
              chatID={activeChatId}
              orderInfo={{
                id: activeChatId,
                name: chatList.find((i) => i.id === activeChatId)?.name,
              }}
              isFirstLoadMess={isFirstLoadMess.current}
              setUnreadMessList={setUnreadMessList}
              emptyTxt={t(activeOrderId ? "emptyTxtChatOrder" : "nothingYet")}
            />
          )}

          {isInfoOpen && (
            <div className={styles.info}>
              {loadingOrderInfo ? (
                <div className={clsx("skeletonBlock", styles.orderLoader)} />
              ) : (
                <>
                  {order && (
                    <>
                      <OrderDescription order={order} layout={"history"} />
                      <footer className={styles.infoFooter}>
                        <Button
                          txt={order.status === 0 ? t("cancel") : "Вернуть в работу"}
                          mode={"warning"}
                          size={"sm"}
                          onClick={() => {
                            setCanceledModal(true)
                          }}
                        />
                      </footer>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </ModalPortal>

      {isCanceledModal && order?.id && (
        <OrderCallbackModal
          layout={order.status === 0 ? "active" : "history"}
          open={isCanceledModal}
          setOpen={setCanceledModal}
          orderId={order.id}
          delFromList={() => {
            if (order.status === 0) {
              dispatch(removeActiveOrdersCount())
            }
          }}
          callbackClose={() => {
            setOpen(false)
            allowScroll(true)
          }}
        />
      )}
    </>
  )
}

export default MessagesModal

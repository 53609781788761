import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { ArrowRightIcon } from "../../../icons/ArrowRightIcon"
import styles from "./BtnObject.module.scss"

interface Props {
  type?: "button" | "div"
  txt?: string
  onClick?: () => void
  iconBack?: boolean
  className?: string
}

const BtnObject = ({ type = "button", txt, onClick, iconBack, className }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  if (type === "button") {
    return (
      <button type={type} onClick={onClick} className={clsx(styles.btn, className)}>
        <img src="/img/object_ico.svg" alt="" className={styles.icon} />
        <span className={styles.content}>
          <span className={styles.txt}>{t(txt ? "selectedObject" : "selectObj")}</span>
          {txt}
        </span>
        {iconBack && <ArrowRightIcon className={styles.iconBack} />}
      </button>
    )
  }
  return (
    <div className={clsx(styles.btn, className)}>
      <img src="/img/object_ico.svg" alt="" className={styles.icon} />
      <span className={styles.content}>
        <span className={styles.txt}>{t(txt ? "selectedObject" : "selectObj")}</span>
        {txt}
      </span>
      {iconBack && <ArrowRightIcon className={styles.iconBack} />}
    </div>
  )
}

export default BtnObject

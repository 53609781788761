import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import { useTranslation } from "react-i18next"
import Search from "../../Search/Search"
import _debounce from "lodash/debounce"
import { useLazyGetChildObjectsQuery } from "../../../redux/api/main"
import { IOrder, ITechObj } from "../../../types/orderTypes"
import RadioRow from "../../Assets/RadioRow/RadioRow"
import Button from "../../Assets/Button/Button"
import clsx from "clsx"
import styles from "./SelectObjectModal.module.scss"
import BtnObject from "../../Assets/BtnObject/BtnObject"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  serviceInfo: IOrder
  callbackSubmit: (el: ITechObj) => void
}

const SelectObjectModal = ({ open, setOpen, serviceInfo, callbackSubmit }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [getChildObjects] = useLazyGetChildObjectsQuery()

  const [searchChat, setSearchChat] = useState<string>("")
  const [objSearch, setObjSearch] = useState<string>("")
  // Переходы по родительскому дереву
  const [objTree, setObjTree] = useState<ITechObj[]>([])
  const [childObjects, setChildObjects] = useState<ITechObj[]>([])
  // Загрузка родительских элементов
  const [isChildrenLoading, setChildrenLoading] = useState(false)
  const [checkedValues, setCheckedValues] = useState<ITechObj[]>([])
  const [step, setStep] = useState<number>(0)

  const debounceFn = useCallback(
    _debounce((str: string) => {
      setObjSearch(str)
    }, 500),
    [],
  )

  useEffect(() => {
    debounceFn(searchChat)
  }, [searchChat])

  const getChildList = (parentId?: string) => {
    setChildrenLoading(true)
    getChildObjects({ serviceId: serviceInfo?.id, parentId: parentId || "", keywords: objSearch })
      .then((result) => {
        setChildrenLoading(false)
        setChildObjects(result.data || [])
      })
      .catch((err) => {
        setChildrenLoading(false)
        console.warn(err)
      })
  }

  useEffect(() => {
    if (!open) return
    // if (objSearch.trim().length > 0) {
    const timeoutId = setTimeout(() => {
      if (!objTree.length) {
        setChildObjects([])
        setObjTree([])
        getChildList()
      } else {
        const currentObject = checkedValues[step - 1]
        getChildList(currentObject.id)
      }
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [objSearch])

  const handleNext = (submit?: boolean) => {
    const currentObject = checkedValues[step]
    if (submit || !currentObject.children_count) {
      callbackSubmit(checkedValues[checkedValues.length - 1])
      setOpen(false)
    } else {
      getChildList(currentObject.id)
      setObjTree((prev) => [...prev, currentObject])
      setStep((prev) => ++prev)
    }
  }
  const handleBack = () => {
    if (objTree?.length) {
      setCheckedValues((prev) => {
        prev.pop()
        return prev
      })
      setChildObjects([])
      setObjTree((pr: any) => {
        const arrDelLast = pr.slice(0, pr.length - 1)
        if (arrDelLast?.length) {
          getChildList(arrDelLast?.[arrDelLast?.length - 1]?.id)
        }
        return [...arrDelLast]
      })
      setStep((prev) => --prev)
    } else {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (!open) {
      setStep(0)
      setObjTree([])
      setCheckedValues([])
      setObjSearch("")
      setSearchChat("")
      setChildObjects([])
    }
  }, [open])

  // При поиске\переходе внутрь объекта выводить локальный список
  const renderList = useMemo(() => {
    return objTree?.length || objSearch ? childObjects : serviceInfo.technicalObjects
  }, [objTree, objSearch, childObjects, serviceInfo])

  return (
    <ModalPortal
      isOpen={open}
      setIsOpen={setOpen}
      className={styles.modal}
      header={<h2 className={"modal__title"}>{t("selectObj")}</h2>}
    >
      <Search searchVal={searchChat} setSearchVal={setSearchChat} className={styles.search} />
      {objTree.length > 0 && (
        <BtnObject
          onClick={() => handleNext(true)}
          className={clsx(styles.selectedObjBtn)}
          txt={objTree?.[objTree?.length - 1]?.name}
          iconBack
        />
      )}

      {isChildrenLoading ? (
        [...Array(3)].map((item, index) => (
          <div key={index} className={clsx("skeletonBlock", styles.childrenLoading)} />
        ))
      ) : renderList.length ? (
        renderList?.map((el: ITechObj, kk: number) => {
          return (
            <RadioRow
              key={kk}
              className={styles.radio}
              name="name"
              title={el.name}
              onChange={() => {
                setCheckedValues((prev) => {
                  prev[step] = el
                  return [...prev]
                })
              }}
              value={el.id}
              checked={checkedValues?.some((item) => item.id === el.id)}
            />
          )
        })
      ) : (
        <p>{t("searchServiceNotFound")}</p>
      )}

      <footer className={styles.footer}>
        <Button className={styles.btnBack} txt={t("back")} onClick={handleBack} mode={"gray"} />
        <Button
          txt={t("next")}
          disabled={!checkedValues?.length || !checkedValues[step] ? true : undefined}
          className={styles.btnNext}
          onClick={() => handleNext()}
        />
      </footer>
    </ModalPortal>
  )
}

export default SelectObjectModal

import SliderNav from "../../Assets/SliderNav/SliderNav"
import { SwiperOptions } from "swiper/types/swiper-options"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { StarIcon } from "../../../icons/StarIcon"
import ArticlePrevItem from "../ArticlePrevItem/ArticlePrevItem"
import { useAppSelector } from "../../../hooks"
import { selectArticles } from "../../../redux/slice/articles"
import "swiper/css"
import styles from "./ArticlesFavorites.module.scss"

const swiperParams: SwiperOptions = {
  slidesPerView: 3,
  modules: [Navigation],
  navigation: {
    prevEl: `.${styles.nav} .slider-prev-btn`,
    nextEl: `.${styles.nav} .slider-next-btn`,
  },
  autoHeight: true,
}

const ArticlesFavorites = () => {
  const { favoriteArticles } = useAppSelector(selectArticles)

  if (!favoriteArticles?.length) return null

  return (
    <div className={styles.wrap}>
      <header className={styles.header}>
        <h4 className={styles.title}>Избранные</h4>
        <span className={styles.iconWrap}>
          <StarIcon />
        </span>
        <SliderNav className={styles.nav} mode={"white"} />
      </header>

      <Swiper {...swiperParams}>
        {favoriteArticles.map((item) => (
          <SwiperSlide key={item.id}>
            <ArticlePrevItem {...item} className={styles.item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default ArticlesFavorites

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticlesList_aside__N7d1O {
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.0509803922);
  background-color: var(--white);
  border-radius: 12px;
  padding: 12px 6px 12px 6px;
  margin-bottom: 24px;
}
@media (min-width: 1200px) {
  .ArticlesList_aside__N7d1O {
    margin-bottom: 0;
  }
}

.ArticlesList_header__7DDzy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  padding: 0 2px 0 12px;
}

.ArticlesList_link__EVStd {
  font-size: var(--fz-lg);
  line-height: var(--lh-lg);
  font-weight: 600;
}

.ArticlesList_btn__Ru02G {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 4px;
  transition: var(--transition);
}
@media (hover: hover) {
  .ArticlesList_btn__Ru02G:hover {
    background-color: var(--accent-color);
    color: var(--accent-text-color);
  }
}
.ArticlesList_btn__Ru02G:last-child {
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Articles/ArticlesList/ArticlesList.module.scss"],"names":[],"mappings":"AAAA;EACE,uDAAA;EACA,8BAAA;EACA,mBAAA;EACA,0BAAA;EACA,mBAAA;AACF;AACE;EAPF;IAQI,gBAAA;EAEF;AACF;;AACA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;EACA,qBAAA;AAEF;;AACA;EACE,uBAAA;EACA,yBAAA;EACA,gBAAA;AAEF;;AACA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,6BAAA;AAEF;AAAE;EACE;IACE,qCAAA;IACA,+BAAA;EAEJ;AACF;AACE;EACE,eAAA;AACJ","sourcesContent":[".aside {\r\n  box-shadow: 0px 5px 9px 0px #0000000D;\r\n  background-color: var(--white);\r\n  border-radius: 12px;\r\n  padding: 12px 6px 12px 6px;\r\n  margin-bottom: 24px;\r\n\r\n  @media (min-width: 1200px) {\r\n    margin-bottom: 0;\r\n  }\r\n}\r\n\r\n.header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  min-height: 32px;\r\n  padding: 0 2px 0 12px;\r\n}\r\n\r\n.link {\r\n  font-size: var(--fz-lg);\r\n  line-height: var(--lh-lg);\r\n  font-weight: 600;\r\n}\r\n\r\n.btn {\r\n  width: 20px;\r\n  height: 20px;\r\n  flex-shrink: 0;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  border-radius: 4px;\r\n  margin-right: 4px;\r\n  transition: var(--transition);\r\n\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      background-color: var(--accent-color);\r\n      color: var(--accent-text-color);\r\n    }\r\n  }\r\n\r\n  &:last-child {\r\n    margin-right: 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aside": `ArticlesList_aside__N7d1O`,
	"header": `ArticlesList_header__7DDzy`,
	"link": `ArticlesList_link__EVStd`,
	"btn": `ArticlesList_btn__Ru02G`
};
export default ___CSS_LOADER_EXPORT___;

import { FC, useState } from "react"
import StarRating from "./StarRating"
import clsx from "clsx"
import styles from "./StarsRating.module.scss"

interface IStarsRating {
  name?: string
  onChange?: (val: number) => void
  disabled?: boolean
  rating?: number
  size?: "sm" | "lg"
  className?: string
}

const StarsRating: FC<IStarsRating> = ({ name = "rating", onChange, disabled, rating = 0, size, className }) => {
  const [val, setVal] = useState<number>(rating)

  return (
    <div className={clsx(styles.stars, size && styles["stars--" + size], className)}>
      {[...Array(5)].map((_item, index) => {
        const ind = 5 - index
        return (
          <StarRating
            key={index}
            value={ind}
            name={name}
            id={`${name}-${ind}`}
            disabled={disabled}
            val={val}
            setVal={setVal}
            onChange={onChange}
          />
        )
      })}
    </div>
  )
}

export default StarsRating

import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react"
import { useLazyGetServiceOrderQuery } from "../../../redux/api/content"
import { selectOpenOrder } from "../../../redux/slice/ordersList"
import { addAllMessage, selectAllMessage, setAllMessage } from "../../../redux/slice/main"
import { useDispatch } from "react-redux"
import Chat from "../../Chat/Chat"
import { useAppSelector } from "../../../hooks"
import clsx from "clsx"
import { getApiUrl } from "../../../utils/getApiUrl"
import { useTranslation } from "react-i18next"
import OrderCallbackModal from "./OrderCallbackModal"
import Button from "../../Assets/Button/Button"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import OrderDescription from "../../OrderDescription/OrderDescription"
import { useLazyGetChatMessagesQuery } from "../../../redux/api/chat"
import styles from "./OrderInfoModal.module.scss"
import useWindowSize from "../../../hooks/useWindowSize"
import TitleBack from "../../TitleBack/TitleBack"
import RatingModal from "../RatingModal/RatingModal"
import { setMobileMenuIsHidden } from "../../../redux/slice/isMoreModal"
import { useLazyGetReviewsByOrderIDQuery } from "../../../redux/api/review"

interface Props {
  id: string
  chatID: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  delFromList?: (orderId: string) => void
  layout?: "history" | "active"
}

const OrderInfoModal: FC<Props> = ({ id, chatID, open, setOpen, delFromList = () => null, layout }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const isFirstLoadMess = useRef<any>(false)
  const { allowScroll } = useScrollBlock()
  const { isDesktop } = useWindowSize()
  const allMessage = useAppSelector(selectAllMessage)

  // Количество непрочитанных сообщений
  const countMessageNoRead = allMessage?.filter((ms: any) => !ms?.is_readed)?.length

  const [getServiceOrder, { data: orderInfo }] = useLazyGetServiceOrderQuery()
  const [getReviewsByOrderID, { data: orderReview }] = useLazyGetReviewsByOrderIDQuery()
  const [getChatMessages] = useLazyGetChatMessagesQuery()
  const [openChat, setOpenChat] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)
  const [loadingChat, setLoadingChat] = useState<boolean>(true)
  const [isCanceledModal, setCanceledModal] = useState<boolean>(false)
  const [isRatingModal, setIsRatingModal] = useState<boolean>(false)

  const closeModal = () => {
    dispatch(setMobileMenuIsHidden(false))
    setOpen(false)
    allowScroll(true)
  }

  useEffect(() => {
    if (typeof window.Echo === "undefined" || !chatID) return
    const channel = window.Echo.join(`chats.${chatID}`)
    channel.listen(".client-addMessage", (event: any) => {
      dispatch(addAllMessage(event))
    })

    return () => {
      window.Echo.leave(`chats.${chatID}`)
    }
  }, [open, chatID])

  useEffect(() => {
    if (!id) return
    getServiceOrder(id)
      .then((resp) => {
        setLoading(false)
        if (resp?.data?.chat_dialog_id) {
          getChatMessages(resp.data.chat_dialog_id)
            .then((res: any) => {
              isFirstLoadMess.current = true
              dispatch(setAllMessage(res.data.aMessages))
              setLoadingChat(false)
            })
            .catch((er) => console.log(er.message))
        }
      })
      .catch((err) => console.warn(err))
    getReviewsByOrderID(id)
  }, [id])

  return (
    <>
      <ModalPortal
        isOpen={open}
        setIsOpen={() => {
          setOpen(!open)
        }}
        className={clsx(layout && !isDesktop && styles["active-or-history-modal"], {
          [styles["opened-mobile-chat"]]: openChat && !isDesktop,
        })}
        header={
          <>
            {isDesktop ? (
              <>
                {!loading && orderInfo?.photos?.[0] && (
                  <img
                    className={styles.img}
                    src={getApiUrl() + "/public/photo/preview/" + orderInfo.photos[0]}
                    alt=""
                  />
                )}

                <h2 className={clsx("modal__title", styles.title, loading && "skeletonBlock")}>
                  {orderInfo?.name} {orderInfo?.count && orderInfo.count > 1 ? <span>x{orderInfo.count}</span> : ""}
                </h2>
              </>
            ) : (
              <>
                {loading ? (
                  <h2 className={clsx(styles.title, loading && "skeletonBlock")} />
                ) : (
                  <TitleBack
                    title={`${orderInfo?.name}
                      ${orderInfo?.count && orderInfo.count > 1 ? `x${orderInfo.count}` : ""}`}
                    onClick={() => {
                      if (openChat) {
                        setOpenChat(false)
                      } else {
                        closeModal()
                      }
                    }}
                    noLinkBack
                  />
                )}
              </>
            )}
          </>
        }
        isCloseBtn={isDesktop}
        bodyNoPadding
      >
        <div className={styles.main}>
          <aside className={styles.aside}>
            {orderInfo && !openChat && (
              <OrderDescription isLoading={loading} order={orderInfo} review={orderReview} layout={layout} />
            )}

            <footer className={styles.footer}>
              {isDesktop ? (
                <Button
                  txt={layout === "active" ? t("cancel") : t("returnToWork")}
                  mode={"warning"}
                  size={"sm"}
                  onClick={() => {
                    setCanceledModal(true)
                  }}
                />
              ) : (
                <div className={`${styles["btn-container"]}`}>
                  <div className={`${styles["btn-container__topTwoBtns"]}`}>
                    <Button
                      txt={layout === "active" ? t("cancel") : t("returnToWork")}
                      mode={"warning"}
                      size={"sm"}
                      onClick={() => {
                        setCanceledModal(true)
                      }}
                    />
                    {layout === "history" && (
                      <Button
                        txt={t("evaluate")}
                        size={"sm"}
                        onClick={() => {
                          setIsRatingModal(true)
                        }}
                      />
                    )}
                  </div>
                  <div className={`${styles["btn-container__bottomTwoBtns"]}`}>
                    <Button
                      txt={t("back")}
                      type="button"
                      onClick={() => {
                        setOpen(false)
                        allowScroll(true)
                      }}
                      className={`btn btn_grey ${styles["back-btn"]}`}
                    />
                    <button
                      type="button"
                      onClick={() => setOpenChat(true)}
                      className={`btn btn_grey ${styles["messanger-btn"]}`}
                    >
                      <img src="/img/chat.svg" alt="" />
                      {t("messages")}
                      {!!countMessageNoRead && (
                        <span className={`${styles.ordersLine__ordersNoReview}`}>{countMessageNoRead}</span>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </footer>
          </aside>
          {(isDesktop || openChat) && (
            <>
              {loadingChat ? (
                <div className={clsx(styles.chatLoader, "skeletonBlock")} />
              ) : (
                <Chat
                  chatID={chatID}
                  orderInfo={orderInfo}
                  isFirstLoadMess={isFirstLoadMess.current}
                  className={`${!isDesktop && styles["mobile-chat"]}`}
                  emptyTxt={t("emptyTxtChatOrder")}
                />
              )}
            </>
          )}
        </div>
      </ModalPortal>

      {isCanceledModal && orderInfo?.id && (
        <OrderCallbackModal
          layout={layout}
          open={isCanceledModal}
          setOpen={setCanceledModal}
          orderId={orderInfo.id}
          delFromList={delFromList}
          callbackClose={() => {
            closeModal()
          }}
          className={!isDesktop ? "order-info-callback-modal" : ""}
        />
      )}
      {layout === "history" && orderInfo?.id && (
        <RatingModal orderId={orderInfo?.id} open={isRatingModal} setOpen={setIsRatingModal} />
      )}
    </>
  )
}

export default OrderInfoModal

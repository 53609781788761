import MessagesAsideItem from "./MessagesAsideItem"
import ScrollBlock from "../../Assets/ScrollBlock"
import { IChatInfo } from "../../../types/content"
import clsx from "clsx"
import { useEffect, useRef } from "react"
import styles from "./MessagesModal.module.scss"

const MessagesAsideList = ({
  list,
  activeChatId,
  isLoading,
  callbackSearch,
  onClick,
}: {
  list: IChatInfo[]
  activeChatId: string
  isLoading?: boolean
  callbackSearch?: () => void
  onClick: (val: string) => void
}) => {
  const loaderRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!callbackSearch) return
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (isLoading) return
      if (target.isIntersecting) callbackSearch()
    })

    if (loaderRef.current) observer.observe(loaderRef.current)

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
      }
    }
  }, [list, isLoading])

  return (
    <ScrollBlock hideTracksWhenNotNeeded fullHeightScrollBar className={styles.asideList}>
      {list.map((item, index) => (
        <MessagesAsideItem
          key={index}
          {...item}
          isActive={item.id === activeChatId}
          onClick={onClick}
          className={styles.item}
        />
      ))}
      <div ref={loaderRef}>
        {isLoading &&
          [...Array(3)].map((i, index) => (
            <div key={index} className={clsx(styles.listLoader)}>
              <div className={clsx("skeletonBlock", styles.listLoader__avatar)} />
              <div>
                <div className={clsx("skeletonBlock", styles.listLoader__name)} />
                <div className={clsx("skeletonBlock", styles.listLoader__txt)} />
              </div>
            </div>
          ))}
      </div>
    </ScrollBlock>
  )
}

export default MessagesAsideList

import Layout from "../../components/Layout/Layout"
import ArticlesAside from "../../components/Articles/ArticlesAside/ArticlesAside"
import Articles from "../../components/Articles/Articles"

export default function ArticlesPage() {
  return (
    <Layout mainClass={"main--md"} aside={<ArticlesAside />}>
      <Articles />
    </Layout>
  )
}

import { FC, ReactNode, useEffect } from "react"
import useSetSettings from "../../hooks/useSetSettings"
import clsx from "clsx"
import Footer from "../Footer"
import Header from "../Header"
import useWindowSize from "../../hooks/useWindowSize"
import OrdersLine from "../OrdersLine/OrdersLine"
import { useAppSelector } from "../../hooks"
import { selectAuth } from "../../redux/slice/auth"
import OrdersLineLoader from "../OrdersLine/OrdersLineLoader"
import MobileMenu from "../MobileMenu/MobileMenu"
import MobileMoreModal from "../Modals/MobileMoreModal/MobileMoreModal"
import { getMobileMenuIsHidden, getOpenIsMoreModal } from "../../redux/slice/isMoreModal"
import LanguageModal from "../Modals/LanguageModal/LanguageModal"
import { updateCountUnreadMessages } from "../../redux/slice/main"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import AuthModal2 from "../Modals/AuthModal2/AuthModal2"

interface Props {
  children: ReactNode
  mainClass?: string
  asideClass?: string
  isHeaderMobHidden?: boolean
  isAsideHidden?: boolean
  aside?: ReactNode
  isUserRequired?: boolean
}

const Layout: FC<Props> = ({
  children,
  mainClass,
  asideClass,
  isHeaderMobHidden,
  isAsideHidden,
  aside,
  isUserRequired = true,
}) => {
  useSetSettings()

  const user = useAppSelector(selectAuth)
  const mobileMoreModalIsOpen = useAppSelector(getOpenIsMoreModal)
  const mobileMenuIsHidden = useAppSelector(getMobileMenuIsHidden)
  const { openModal } = useSelector((state: any) => state.authModal)
  const { isDesktop } = useWindowSize()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (!user?.user?.id) return
    const channel = window.Echo.private(`privateUser.${user.user.id}`)
    channel?.listen(".changePublicUnreadMessages", (event: { count: number }) => {
      if (event?.count === 0 || event?.count) {
        dispatch(updateCountUnreadMessages(event.count || 0))
      }
    })

    return () => {
      window.Echo.leave(`privateUser.${user.id}`)
    }
  }, [user?.user?.id])

  if (openModal) return <AuthModal2 />

  return (
    <div className="wrapper">
      <Header isMobHidden={isHeaderMobHidden} />
      <main className={clsx(mainClass, !isDesktop && "layout__footer-padding")}>
        {isDesktop && !isAsideHidden && (
          <aside className={clsx("main-aside", asideClass)}>
            <div className={"main-aside__inner"}>
              {aside ? aside : user.token ? <OrdersLine /> : <OrdersLineLoader />}
            </div>
          </aside>
        )}
        <div className={clsx("main-content", isAsideHidden && "main-content--full")}>
          {isUserRequired ? <>{user?.user?.id && children}</> : children}
        </div>
      </main>
      {isDesktop && <Footer />}
      {!isDesktop && !mobileMenuIsHidden && <MobileMenu />}
      {user.token && !isDesktop && mobileMoreModalIsOpen && <MobileMoreModal />}
      {!isDesktop && <LanguageModal />}
    </div>
  )
}

export default Layout

import LocalizedLink from "../../../hoc/LocalizedLink"
import IconBtn from "../../Assets/IconBtn/IconBtn"
import ArticlesList from "../ArticlesList/ArticlesList"
import { useAppSelector } from "../../../hooks"
import { selectAuth } from "../../../redux/slice/auth"
import { useGetArticlesQuery } from "../../../redux/api/articles"
import clsx from "clsx"
import styles from "./ArticlesAside.module.scss"
import useWindowSize from "../../../hooks/useWindowSize"

const ArticlesAside = () => {
  const { user } = useAppSelector(selectAuth)
  const { isDesktop } = useWindowSize()

  const { data: articles, isLoading } = useGetArticlesQuery()

  return isLoading ? (
    <>
      {isDesktop && <div className={clsx("skeletonBlock", styles.loaderBtn)} />}
      <div className={clsx("skeletonBlock", styles.loader)} />
    </>
  ) : (
    <>
      {isDesktop && (
        <>
          {user ? (
            <LocalizedLink to={"/"} className={styles.btn}>
              <IconBtn icon={"back"} borderSize={"circle"} type={"span"} className={styles.iconBack} />
              <span>
                Главное меню<span className={styles.btnTxt}>Вернуться назад</span>
              </span>
            </LocalizedLink>
          ) : (
            <div className={styles.btn}>
              <IconBtn icon={"book"} borderSize={"circle"} type={"span"} className={styles.iconBack} />
              <span>
                База знаний<span className={styles.btnTxt}>Самые полезные статьи</span>
              </span>
            </div>
          )}
        </>
      )}

      {user && articles && <ArticlesList articles={articles} />}
    </>
  )
}

export default ArticlesAside

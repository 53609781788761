import { Dispatch, memo, SetStateAction, useEffect, useMemo, useRef, useState } from "react"
import Button from "../../Assets/Button/Button"
import clsx from "clsx"
import IconBtn from "../../Assets/IconBtn/IconBtn"
import { ITag } from "../../../types/content"
import styles from "./ArticlesFilter.module.scss"

interface Props {
  list: ITag[]
  className?: string
  filter: {
    search: string
    tags: string[]
  }
  setFilter: Dispatch<
    SetStateAction<{
      search: string
      tags: string[]
    }>
  >
}

const ArticlesFilter = ({ list, className, filter, setFilter }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [size, setSize] = useState(0)
  const [num, setNum] = useState<number>(0)
  const [labelsList, setLabelsList] = useState<ITag[]>([])
  const [isFinish, setFinish] = useState<boolean>(false)
  const [isOpen, setOpen] = useState<boolean>(false)

  const addItem = (index: number) => {
    setLabelsList((prev) => [...prev, list[index]])
    setNum(index + 1)
  }
  const removeItem = () => {
    if (!labelsList) return
    setLabelsList((prev) => {
      prev.splice(-1, 1)
      return prev
    })
  }

  useEffect(() => {
    if (!ref.current) return
    if (!num) return
    if (num === list.length) return
    const height = ref.current.offsetHeight
    const minH = window.innerWidth <= 1200 ? 110 : 32
    if (height <= minH) {
      addItem(num)
    } else {
      removeItem()
      setFinish(true)
      setSize(num - 1)
    }
  }, [num])

  useEffect(() => {
    addItem(0)
  }, [list])

  const toggleList = () => {
    setLabelsList(isOpen ? list.slice(0, size) : list)
    setOpen((prev) => !prev)
  }

  const listOutput = useMemo(() => {
    return (
      <>
        {labelsList?.map((item) => {
          return (
            <Button
              key={item.id}
              txt={item.name}
              mode={"white"}
              size={"sm"}
              className={clsx(styles.label, filter.tags.includes(item.id) && styles["label--is-active"])}
              onClick={() => {
                setFilter((prev) => {
                  if (prev.tags.includes(item.id)) {
                    return { ...prev, tags: [...prev.tags.filter((prevItem) => prevItem !== item.id)] }
                  } else {
                    return { ...prev, tags: [...prev.tags, item.id] }
                  }
                })
              }}
            />
          )
        })}
        {num < list?.length && (
          <IconBtn icon={"down"} mode={"white"} borderSize={"circle"} onClick={toggleList} className={styles.btn} />
        )}
      </>
    )
  }, [isFinish, labelsList, filter])

  return (
    <div ref={ref} className={clsx(styles.grid, isOpen && styles["grid--is-open"], className)}>
      {listOutput}
    </div>
  )
}

export default memo(ArticlesFilter)

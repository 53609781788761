import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type ParamsState = {
  isSearchVal: boolean
  isOrderWithoutRevCheck: boolean
}

const initialState: ParamsState = {
  isSearchVal: false,
  isOrderWithoutRevCheck: false,
}

export const paramsSlice = createSlice({
  name: "params",
  initialState,
  reducers: {
    setIsSearchVal: (state: ParamsState, action: PayloadAction<boolean>) => {
      state.isSearchVal = action.payload
    },
    setOrderWithoutRevCheck: (state: ParamsState, action: PayloadAction<boolean>) => {
      state.isOrderWithoutRevCheck = action.payload
    },
  },
})

export const { setIsSearchVal, setOrderWithoutRevCheck } = paramsSlice.actions

export const selectParams = (state: any): ParamsState => state[paramsSlice.name]

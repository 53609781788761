import SelectObjectModal from "../Modals/SelectObjectModal/SelectObjectModal"
import { useState } from "react"
import { IOrder, TechnicalObject } from "../../types/orderTypes"
import clsx from "clsx"
import BtnObject from "../Assets/BtnObject/BtnObject"

const SelectObject = ({
  data,
  callbackSubmit,
  className,
}: {
  data: IOrder
  callbackSubmit: (el: TechnicalObject) => void
  className?: string
}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [name, setName] = useState<string>("")

  return (
    <>
      <BtnObject
        type={data.service_action_name ? "button" : "div"}
        onClick={() => setModalOpen(true)}
        className={clsx(className)}
        txt={!data?.service_action_name && data.technicalObjects?.length ? data.technicalObjects[0].name : name}
      />

      <SelectObjectModal
        open={isModalOpen}
        setOpen={setModalOpen}
        serviceInfo={data}
        callbackSubmit={(el) => {
          if (!el) return
          setName(el.name)
          callbackSubmit(el)
        }}
      />
    </>
  )
}

export default SelectObject
